import { ReactNode } from 'react';
import React from 'react';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';
import classes from './LinkButton.module.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export enum LinkButtonVariant {
  Plain = 'plain',
  Primary = 'primary',
  Secondary = 'secondary',
  Neutral = 'neutral',
}

export enum LinkButtonFit {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum LinkButtonIconPosition {
  Left = 'left',
  Right = 'right',
}

interface LinkButtonProps {
  children: ReactNode;
  icon?: string;
  to: string;
  variant?: LinkButtonVariant;
  external?: boolean;
  fit?: LinkButtonFit;
  iconPosition?: LinkButtonIconPosition;
  state?: Record<string, unknown>;
}

const LinkContent = ({
  children,
  variant = LinkButtonVariant.Primary,
  icon,
  fit = LinkButtonFit.Medium,
  iconPosition = LinkButtonIconPosition.Left,
}: LinkButtonProps) => {
  return (
    <div className={classnames(classes.link, classes[variant], classes[fit])}>
      {icon && iconPosition === LinkButtonIconPosition.Left && (
        <McIcon size={'20'} icon={icon} />
      )}
      <div>{children}</div>
      {icon && iconPosition === LinkButtonIconPosition.Right && (
        <McIcon
          size={'20'}
          icon={icon}
          color={
            variant === LinkButtonVariant.Primary ? '#fff' : 'rgb(20, 20, 20)'
          }
        />
      )}
    </div>
  );
};

const LinkButton = (props: LinkButtonProps) => {
  if (props.external) {
    return (
      <a
        href={props.to}
        rel={'noreferrer noopener'}
        target={'_blank'}
        className={classes.linkCont}
      >
        <LinkContent {...props}>{props.children}</LinkContent>
      </a>
    );
  }

  return (
    <Link to={props.to} state={props.state} className={classes.linkCont}>
      <LinkContent {...props}>{props.children}</LinkContent>
    </Link>
  );
};

export default LinkButton;
