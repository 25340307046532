// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!../node_modules/@anchor/react-components/dist/lib/css/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
  transition: all 0.5s ease-in-out;
}

.App_white-text__90pUU {
  color: white !important;
}

a {
  color: #058ec6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Safari Fixes */
input,
textarea,
select,
button {
  margin: 0;
}

.App_loading-screen__KJrxG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.App_capella-logo__KLN8R {
  margin-bottom: 10px;
}

.App_loading-text__X-ZSc {
  color: #333;
  font-family: "MaerskFont", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAEA;EACE,8CAAA;EACA,qCAAA;EACA,gCAAA;AAAF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,cAAA;EACA,qBAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA,iBAAA;AACA;;;;EAIE,SAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,qCAAA;AADF","sourcesContent":["@import '~@anchor/react-components/dist/lib/css/global.css';\n// @import '../src/styles/var.scss';\nbody {\n  background-color: var(--background) !important;\n  color: var(--text-primary) !important;\n  transition: all 0.5s ease-in-out;\n  // overflow-x: hidden;\n  // overflow-y: scroll;\n}\n.white-text {\n  color: white !important;\n}\n\na {\n  color: #058ec6;\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n/* Safari Fixes */\ninput,\ntextarea,\nselect,\nbutton {\n  margin: 0;\n}\n\n.loading-screen {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: 200px;\n}\n\n.capella-logo {\n  margin-bottom: 10px;\n}\n\n.loading-text {\n  color: #333;\n  font-family: 'MaerskFont', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white-text": `App_white-text__90pUU`,
	"loading-screen": `App_loading-screen__KJrxG`,
	"capella-logo": `App_capella-logo__KLN8R`,
	"loading-text": `App_loading-text__X-ZSc`
};
export default ___CSS_LOADER_EXPORT___;
