import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.module.scss';
import '@maersk-global/fonts/maeu/fonts.css';
import '@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px.css';
import '@maersk-global/mds-foundations/css/foundations.css';
import routes from './routes';
import logo from './assets/SvgFiles/capella_logo_rgb.svg';
import { authenticationService } from './shared-services/shared-services-authentication-service';
import { ErrorProvider } from './provides/ErrorProvider';
import ErrorDisplay from './components/ErrorDisplay/ErrorDisplay';
const App = () => {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    authenticationService
      .getAccessToken()
      .then((response) => {
        setToken(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching token:', error);
        setLoading(false);
      });
  }, []);

  if ((loading && token === null) || token === undefined) {
    return (
      <div
        className="loading-screen"
        style={{ textAlign: 'center', marginTop: '300px' }}
      >
        <img
          src={logo}
          alt="Capella Logo"
          className="capella-logo"
          width="200px"
        />
        <p
          className="loading-text"
          style={{ fontSize: '18px', fontWeight: '700' }}
        >
          Authentication in progress...
        </p>
      </div>
    );
  }

  const router = createBrowserRouter(routes);
  return (
    <ErrorProvider>
      <ErrorDisplay />
      <RouterProvider router={router} />
    </ErrorProvider>
  );
};

export default App;
