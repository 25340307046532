import React, { useEffect, useState } from 'react';
import classes from './UsersBadge.module.scss';
import classnames from 'classnames';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';

export interface UserBadgeProps {
  user: {
    displayName: string;
    mail: string;
  };
  accessToken: string;
}

const UsersBadge: React.FC<UserBadgeProps> = ({ user, accessToken }) => {
  const [userPhoto, setUserPhoto] = useState<string | null>(null);

  // Fetch the user's profile photo from Microsoft Graph API
  useEffect(() => {
    if (user && accessToken) {
      fetchUserPhoto();
      fetchUserEmail();
    }
  }, [user, accessToken]);
  const fetchUserEmail = async () => {
    try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Error fetching user email');
      const data = await response.json();
    } catch (error) {
      console.error('Failed to fetch user email:', error);
    }
  };
  const fetchUserPhoto = async () => {
    try {
      const response = await fetch(
        'https://graph.microsoft.com/v1.0/me/photo/$value',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) throw new Error('Error fetching user photo');
      const blob = await response.blob();
      setUserPhoto(URL.createObjectURL(blob)); // Convert photo blob to URL
    } catch (error) {
      console.error('Failed to fetch user photo:', error);
    }
  };

  // Create initials from the user's display name
  const nameSplit = user?.displayName?.split(' ') ?? [];
  const initials = `${nameSplit[0]?.charAt(0) ?? ''}${nameSplit[1]?.charAt(0) ?? ''}`;

  return (
    <div className={classnames(classes.initialsCont)}>
      {userPhoto ? (
        <img
          className={classes.icon}
          src={userPhoto}
          alt="User Avatar"
          onError={(e) => {
            e.currentTarget.src = 'https://via.placeholder.com/150';
          }}
          width="32"
          height="32"
        />
      ) : (
        <span className={classes.initials}>
          {initials || <McIcon icon="user" />}
        </span>
      )}
    </div>
  );
};

export default UsersBadge;
