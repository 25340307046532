import classes from './Button.module.scss';
import { HTMLProps, MouseEvent, ReactNode } from 'react';
import React from 'react';
export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
}

export enum ButtonAppearance {
  Primary = 'primary',
  Danger = 'danger',
  Link = 'link',
  Plain = 'plain',
}

export enum ButtonFit {
  Medium = 'medium',
  Small = 'small',
  ExtraSmall = 'extraSmall',
}

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: ButtonTypes;
  className?: string;
  children: ReactNode;
  click?: (activityLog: MouseEvent) => void;
  appearance?: ButtonAppearance;
  fit?: ButtonFit;
}

const Button = ({
  type = ButtonTypes.Button,
  className,
  children,
  click,
  appearance = ButtonAppearance.Primary,
  fit = ButtonFit.Medium,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`${classes.button} ${classes[appearance]} ${classes[fit]} ${
        className ?? ''
      }`}
      onClick={click}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
