import React from 'react';
import Header from '../dashboard/header';

const AccessDenied: React.FC = () => {
  return (
    <>
      <Header />
      <div style={{ textAlign: 'center', marginTop: '300px' }}>
        <h1>Access Denied</h1>
        <p>You do not have permission to access this page.</p>
      </div>
    </>
  );
};

export default AccessDenied;
