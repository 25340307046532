import React, { useEffect } from 'react';
import { useError } from '../../hooks/useError';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';

const ErrorDisplay: React.FC = () => {
  const { error, setError } = useError();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // Error message will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  if (!error) return null;

  return (
    <McToast
      appearance="error"
      title="Error"
      content={error}
      close={() => setError(null)}
    />
  );
};

export default ErrorDisplay;
