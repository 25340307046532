import { useError } from '../provides/ErrorProvider';

export const useHandleError = () => {
  const { setError } = useError();

  const handleError = (message: string) => {
    setError(message);
  };

  return handleError;
};
export { useError };
