import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AccessDenied from './components/AccessDenied/AccessDenied';
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));
const PlatformPage = lazy(
  () => import('../src/pages/PlatformPage/PlarformPage')
);
const BlueprintPage = lazy(() => import('./pages/BlueprintPage/BlueprintPage'));
const BlueprintVersionPage = lazy(
  () => import('./pages/BlueprintVersionPage/BlueprintVersionPage')
);
const DocumentationPage = lazy(
  () => import('./pages/Documentation/Documentation')
);
const NotFoundPage = lazy(() => import('./components/NotFound/NotFound'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LandingPage />,
  },
  ,
  {
    path: 'platform/:platform/environment/:environment/:tab',
    element: <PlatformPage />,
  },
  {
    path: 'blueprints',
    element: <BlueprintPage />,
  },
  {
    path: 'blueprints/:blueprint/versions',
    element: <BlueprintVersionPage />,
  },
  {
    path: 'documentation',
    element: <DocumentationPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/access-denied',
    element: <AccessDenied />,
  },
];

export default routes;
