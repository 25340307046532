import React, { useState, useEffect } from 'react';
import { McMenu } from '@maersk-global/mds-react-wrapper/components-core/mc-menu';
import Button, { ButtonAppearance } from '../Button/Button';
import classes from './Login.module.scss';
import {
  authenticationService,
  ExternalScopes,
} from 'src/shared-services/shared-services-authentication-service';
import UsersBadge from './UsersBadge';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';
interface User {
  displayName: string;
  mail: string;
}
const Login: React.FC = () => {
  const [user, setUser] = useState<User>(null);
  const [graphAccessToken, setGraphAccessToken] = useState<string | null>(null);
  const [apiAccessToken, setApiAccessToken] = useState<string | null>(null);

  const handleLogout = async () => {
    await authenticationService.logOut();
  };
  const getGraphToken = async () => {
    return await authenticationService.getAccessToken([
      ExternalScopes.graphScope,
    ]);
  };
  const getApiToken = async () => {
    return await authenticationService.getAccessToken([
      'api://b506118d-67ee-44d4-9bee-61bf30f7866d/user_impersonation',
    ]);
  };
  const fetchUserDetails = async (accessToken: string) => {
    try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // Automatically trigger login and token retrieval
  useEffect(() => {
    const authenticateUser = async () => {
      const account = await authenticationService.getAccount();
      if (!account) {
        await authenticationService.loginMsalRedirect();
      } else {
        const graphToken = await getGraphToken();
        setGraphAccessToken(graphToken);
        const apiToken = await getApiToken();
        setApiAccessToken(apiToken);

        // Fetch full user details from Microsoft Graph
        if (graphToken) {
          fetchUserDetails(graphToken);
        }
      }
    };
    authenticateUser();
  }, []);
  // Create initials from the user's display name
  const nameSplit = user?.displayName?.split(' ') ?? [];
  const initials = `${nameSplit[0]?.charAt(0) ?? ''}${nameSplit[1]?.charAt(0) ?? ''}`;
  return (
    <McMenu position={'bottom-right'}>
      <Button
        slot={'trigger'}
        appearance={ButtonAppearance.Plain}
        className={classes.menuActivator}
      >
        {apiAccessToken?.length > 0 ? (
          <span className={classes.user_initials}>
            {initials || <McIcon icon="computer" color="#fff" />}
          </span>
        ) : (
          <McIcon icon="user" />
        )}
      </Button>
      <div className={classes.menu_content}>
        <div className={classes.user_img_container}>
          <UsersBadge user={user} accessToken={graphAccessToken || ''} />
          <span onClick={handleLogout} className={classes.user_signout}>
            Sign out
          </span>
        </div>
        <div className={classes.user_info_container}>
          <span className={classes.user_name}>{user?.displayName || ''}</span>
          <span className={classes.user_email}>{user?.mail || ''}</span>
        </div>
      </div>
    </McMenu>
  );
};

export default Login;
